<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6">
                <form action="#">
                    <div class="input-group input-group-lg">
                        <input type="search" class="form-control form-control" placeholder="Cari Try Out">
                        <div class="input-group-append">
                            <button type="submit" class="btn btn btn-default">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <br>
            <div  class="row">
                <div v-for="row in optMapel" v-bind:key="row.paket_id"
                v-bind:value="row.paket_id" class="col-md-4 col-sm-8 col-12">
                <router-link :to="{
                    path: '/ujian-mapel-list/'+row.paket_id+'/ujian/'+filter.jenis}">
                    <div class="info-box shadow-sm">
                    <span class="info-box-icon bg-info"><i class="fas fa-scroll"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">{{ row.nama_paket }}</span>                        
                        <span class="info-box-number">{{ row.item_paket }} Try Out tersedia</span>
                    </div>
                    </div>
                </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
export default {
    name: 'UjianMapelFilter',
    data: () => ({
        optMapel: [],
        filter: {},
    }),
    created: function () {
        let id = this.$route.params.id;
        if(this.$route.params.filter) {
        this.filter = this.$route.params.filter;
        }
        
        if(Object.keys(this.filter).length<1)
        {
        if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
        }
        else{
        sessionStorage.filterData = JSON.stringify(this.filter);
        sessionStorage.filterStatus = 1;
        }

        this.loadUjian(id,this.filter);
    },
    methods: {
        // loadUjian(id, params){
		// 	authFetch("/siswa/ujian/filter/" + id).then((res) => {
		// 		res.json().then((json) => {
		// 		this.ujian = json;
		// 		});
		// 	});
        // },
         loadUjian(id, params){
             let data = '';
             data = Object.keys(params)
             .map(
             (key) =>
                 encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
             )
             .join("&");
             authFetch("/siswa/ujian/filter/"+id).then((res) => {
                 res.json().then((json) => {
                 if (json.success) {
                     this.optMapel = json.data;
                 }
                 });
             });
         }
    },
    mounted(){
        // this.loadUjian();
    },
}
</script>
<style type="text/css" scoped>
</style>